import { Link } from 'react-scroll';
import styles from './header.module.css';

function Header() {

  return (
    <div className={styles.header}>
      <div className={styles.top}>
        <div className={styles.title}>
          My Apps
        </div>
        <div className={styles.icon}>
          <a className={styles.link} target="_blank" rel="noopener noreferrer">
            <i className="bi bi-instagram"></i>
          </a>
        </div>
      </div>

      <div className={styles.bottom}>
        <Link
          to="appsSection"
          className={styles.field}
          smooth={true}
          duration={300}
        >
          Apps
        </Link>          
      </div>
    </div>
  );
}
  
export default Header;
  