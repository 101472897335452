import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from './login.module.css';

function Login() {

//Definition

  //variables
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.state.pathname;
    
  //functions
    const handleLogin = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_LOGIN, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify({ username, password }),
                credentials: "include"
            });

            if (response.status === 200) {
                navigate(pathname);
            }
            else {
                alert("Login error.")
                navigate("/");
            }   
        }
        catch (error) {
            alert("Network error.");
        }
    };

   
//display
    return (
        <div className={styles.login}>
            <div className={styles.login_container}>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="UserName"
                    className={styles.login_form}
                    style={{marginTop: "6rem"}}
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className={styles.login_form}
                />
                <button type="button" className={styles.login_button} onClick={handleLogin}>
                    Login
                </button>
            </div>
        </div>
    )
}

export default Login;