import { Routes, Route } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';
import Home from "./components/Home";
import './Reset.css'

import Budget from "./components/apps/budget/Budget";
import Editor from "./components/apps/budget/elements/Editor";
import Planner from "./components/apps/budget/elements/Planner";
import Payment from "./components/apps/budget/elements/Payment";

import Workout from "./components/apps/workout/Workout";
import Record from "./components/apps/workout/Record";
import Edit from "./components/apps/workout/Edit";

import Food from "./components/apps/food/Food";
import FoodResults from "./components/apps/food/FoodResults";
import FoodEdit from "./components/apps/food/FoodEdit";

import Making from "./components/apps/Making";
import Auth from "./components/Auth";
import Login from "./components/Login";
import Update from "./components/Update";
import Memo from "./components/apps/memo/Memo";
import MemoRecord from "./components/apps/memo/MemoRecord";
import MemoEdit from "./components/apps/memo/MemoEdit";
import Item from "./components/apps/memo/elements/Item";





function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Auth><Home /></Auth>}/>

        <Route path="/auth" element={<Auth />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/update" element={<Update />}/>
        
        <Route path="/apps/budget" element={<Auth><Budget /></Auth>}>
          <Route path="/apps/budget/planner" element={<Planner />}/>
          <Route path="/apps/budget/editor" element={<Editor />}/>
          <Route path="/apps/budget/payment" element={<Payment />}/>
        </Route>

        <Route path="/apps/workout" element={<Auth><Workout /></Auth>}>
          <Route path="/apps/workout/record" element={<Record />}/>
          <Route path="/apps/workout/edit" element={<Edit />}/>
        </Route>
        
        <Route path="/apps/food" element={<Auth><Food /></Auth>}>
          <Route path="/apps/food/results" element={<FoodResults />}/>
          <Route path="/apps/food/edit" element={<FoodEdit />}/>
        </Route>

        <Route path="/apps/memo" element={<Auth><Memo /></Auth>}>
          <Route path="/apps/memo/record" element={<MemoRecord />}/>
          <Route path="/apps/memo/record/:id" element={<Item/>}/>
          <Route path="/apps/memo/edit" element={<MemoEdit />}/>
        </Route>


        <Route path="/apps/making" element={<Making />}/>



      </Routes>
    </div>
  );
}

export default App;
