import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";


function Auth( {children} ) {
    
//Definition
  //variables
    const [auth, setAuth] = useState(false);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

  //functions
    const checkAuth = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_AUTH, {
                method: "GET",
                credentials: "include"
            });
           
            if(response.status === 200) {
                setAuth(true);
            }
        }
        catch (error) {
            alert("Network error.");
            setAuth(false);
        }
        finally {
            setLoading(false);
        }
    };

//Render
    useEffect(() => {
        checkAuth();
    }, []);

//display
    if(loading) {
        return (
            <div className="auth-Loading">
                ログイン認証中...
            </div>
        )
    }

    if (auth) {
        return children;
    }
    else {
        return <Navigate to="/login" state={{pathname: location.pathname}}/> ;
    }
}

export default Auth;

