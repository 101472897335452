import styles from '../css/recordbox.module.css';

function RecordBox(props) {

  return (
    <div className={styles.box}>
      <div className={styles.title}>
        {props.title}
      </div>
      <div className={styles.items}>
        {props.items.map(item => (
          <div className={styles.item} key={item.id}>
            <div className={styles.name}>
              {item.name}
            </div>
            <div className={styles.weight}>
              {item.weight}kg
            </div>
            <div className={styles.times}>
              {item.times}回
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
  
export default RecordBox;
  