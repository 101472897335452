import { Link, useLocation } from 'react-router-dom';
import styles from './css/header.module.css';

function Header() {

  const location = useLocation();
  const title = location.pathname.split('/');

  return (
    <div className={styles.header}>
        <div className={styles.title}>
          Budget {title[title.length -1]}
        </div>
        <Link to="/" className={styles.button}>
          <i className="bi bi-house"></i>
        </Link>
    </div>
  );
}
  
export default Header;
  