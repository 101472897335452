import Header from './header/Header';
import Footer from './footer/Footer';
import Main from './main/Main';

function Home() {

  
 
  return (
    <>
      <Header/>
      <Main/>
      <Footer/>
    </>
  );
}
  
export default Home;
  