import styles from '../css/resultcircle.module.css';

function ResultCircle(props) {

  return (
    <div className={styles.circle}>
      <div className={styles.title}>
        {props.title}
      </div>
      <div className={styles.result}>
        {props.result}
      </div>
    </div>
  );
}
  
export default ResultCircle;