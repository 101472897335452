import { Outlet } from "react-router-dom";
import Header from "./elements/Header";
import Footer from "./elements/Footer";
import { useState } from "react";

function Memo() {

  const [ instaItems, setInstaItems ] = useState([{id: "1", date: "2024-05-01", title: "idea1", summary: "better", point: "充実に強調", category: "Instagram"}, {id: "8", date: "2024-05-01", title: "idea1", summary: "better", point: "充実に強調", category: "Instagram"}]);
  const [ podItems, setPodItems ] = useState([{id: "2", date: "2024-05-01", title: "idea11", summary: "better", point: "充実に強調", category: "Podcast"}]);
  const [ xItems, setXItems ] = useState([{id: "3", date: "2024-05-01", title: "idea21", summary: "better", point: "充実に強調", category: "X"}]);
  const [ appItems, setAppItems ] = useState([{id: "4", date: "2024-05-01", title: "idea31", summary: "better", point: "充実に強調", category: "App"}]);
  const [ impItems, setImpItems ] = useState([{id: "5", date: "2024-05-01", title: "idea41", summary: "better", point: "充実に強調", category: "Improvement"}]);
 
  const contexts = {
    instaItems,
    setInstaItems,
    podItems,
    setPodItems,
    xItems,
    setXItems,
    appItems,
    setAppItems,
    impItems,
    setImpItems,
  };

  return (
    < >
      <Header />
      <Outlet context={contexts} />
      <Footer />
    </>
  );
}
  
export default Memo;
  