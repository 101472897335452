import { Link } from "react-router-dom";
import styles from './css/logout.module.css';

function Logout() {
//Definition

  //functions
    const handleLogout = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_LOGOUT, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if(response.ok) {
                window.location.reload();
            }
            else {
                alert("Error.");
            }
        }
        catch (error) {
            alert("Error. Try agian.")
        }
    }   

//Display
    return (
        <div className={styles.logout}>
            <button className={styles.logout_button} onClick={handleLogout}>
                ログアウト
            </button>
            <Link to="/update" className={styles.update_button}>
                パスワード更新
            </Link>
        </div>
    )
};

export default Logout;