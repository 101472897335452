import styles from '../css/paymentbox.module.css';

function EditorPayment({ useditem, setUsedItem, filteredMonth, setFilteredMonth }) {
  
  const displayItems = [...useditem]
    .filter(item => filteredMonth === 0 || item.date.month === filteredMonth)
    .reverse();


  function deleteItems(inputId) {
    const updatedItems = useditem.filter(item => item.id !== inputId);
    setUsedItem(updatedItems);
  };

  return (
      <div className={styles.box}>
        <div className={styles.title}>
          Used Record　
          <select className={styles.select} value={filteredMonth} onChange={(e) => setFilteredMonth(Number(e.target.value))}>
            <option className={styles.option} value={0}>全て</option>
            {[...Array(12).keys()].map((_, index) => (
              <option className={styles.option} value={index + 1} key={index + 1}>{index + 1}月</option>
            ))}
          </select>
        </div>
        <div className={styles.items}>
          {displayItems.map((item) => (
            <div className={styles.item} key={item.id}>
              <div className={styles.date}>
                {item.date.month}/{item.date.day}
              </div>
              <div className={styles.amount}>
                {item.amount}円
              </div>
              <div className={styles.detail}>
                {item.placeholder}
              </div>
              <button className={styles.delete} onClick={() => deleteItems(item.id)}>
                delete
              </button>
            </div>
          ))}
        </div>
      </div>

  );
}
  
export default EditorPayment;