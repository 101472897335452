import { useOutletContext } from 'react-router-dom';
import styles from '../css/planner.module.css';
import PlannerBox from './PlannerBox';
import SaveButton from './SaveButton';
import PlannerCircle from './PlannerCircle';

function Planner() {

  const {
    bankitem,
    setBankItem,
    incomeitem,
    setIncomeItem,
    payitem,
    setPayItem,
    names,
    useditem,
    today,
    isLoading,
    setIsLoading
  } = useOutletContext();

  return (
    <div className={styles.planner}>
      <PlannerCircle bankitem={bankitem} incomeitem={incomeitem} payitem={payitem} setBankItem={setBankItem} setIncomeItem={setIncomeItem} setPayItem={setPayItem} useditem={useditem} today={today}/>
      <PlannerBox items={bankitem} setItems={setBankItem} title={names[0]}/>
      <PlannerBox items={incomeitem} setItems={setIncomeItem} title={names[1]}/>
      <PlannerBox items={payitem} setItems={setPayItem} title={names[2]}/>
      <SaveButton bankitem={bankitem} incomeitem={incomeitem} payitem={payitem} useditem={useditem} isLoading={isLoading} setIsLoading={setIsLoading}/>
     </div>
  );
}
  
export default Planner;
  