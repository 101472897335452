import styles from '../css/paymentbox.module.css';

function PaymentBox(props) {

  const displayItems = [...props.useditem]
    .filter(item => props.filteredMonth === 0 || item.date.month === props.filteredMonth)
    .reverse();

  return (
      <div className={styles.box}>
        <div className={styles.title}>
          Used Record　
          <select className={styles.select} value={props.filteredMonth} onChange={(e) => props.setFilteredMonth(Number(e.target.value))}>
            <option className={styles.option} value={0}>全て</option>
            {[...Array(12).keys()].map((_, index) => (
              <option className={styles.option} value={index + 1} key={index + 1}>{index + 1}月</option>
            ))}
          </select>
        </div>
        <div className={styles.items}>
          {displayItems.map((item) => (
            <div className={styles.item} key={item.id}>
              <div className={styles.date}>
                {item.date.month}/{item.date.day}
              </div>
              <div className={styles.amount}>
                {item.amount}円
              </div>
              <div className={styles.detail}>
                {item.placeholder}
              </div>
            </div>
          ))}
        </div>
      </div>

  );
}
  
export default PaymentBox;
  