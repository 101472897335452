import { useParams } from "react-router-dom";

function Item() {

  const { id } = useParams();

  return (
    <div style={{paddingTop: "8vh", paddingBottom: "8vh"}}>
    {id}
    </div>
  )


}

export default Item;