import { useOutletContext } from "react-router-dom";
import RecordBox from "./elements/RecordBox";

function Record() {

  const {
    chestItems,
    backItems,
    shoulderItems,
    armItems,
    legItems
  } = useOutletContext();

  return (
    <div style={{paddingTop: "8vh", paddingBottom: "8vh", backgroundColor: "rgb(110, 110, 110)"}}>
      <RecordBox items={chestItems} title={"Chest"} />
      <RecordBox items={backItems} title={"Back"} />
      <RecordBox items={shoulderItems} title={"Shoulder"} />
      <RecordBox items={armItems} title={"Arm"} />
      <RecordBox items={legItems} title={"Leg"} />
    </div>
  );
}
  
export default Record;
  