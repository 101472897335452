import { Oval } from 'react-loader-spinner';
import { UpdateDB } from '../UpdateDB';
import styles from '../css/savebutton.module.css';

function AddButton({ useditem, setUsedItem, newitem, setNewItem, today, isLoading, setIsLoading, } ) {


  const handleAdd = async () => {
    if(newitem.amount.trim()!=="" && newitem.placeholder.trim()!=="") {
      setIsLoading(true);
      const setItem = {...newitem, id: Math.random().toString(36).substring(7), date: {month: today.getMonth() +1, day: today.getDate()}};
      const saveData = [...useditem, setItem];
      await UpdateDB(saveData, process.env.REACT_APP_API_BUDGET_ADD_P);
      setUsedItem(saveData);
      setNewItem({...newitem, placeholder: "", amount: "", date: ""});
      setIsLoading(false);
    }
    else{
      alert("write cost & detail");
    }
  };


  return (
    <div className={styles.savebutton}>
      <button className={styles.button} onClick={handleAdd}>
        Add
      </button>
      {isLoading && 
        <div className={styles.spinnerbox}>
          <Oval
            visible={true}
            width="2rem"
            height="2rem"
            color="rgb(255, 166, 77)"
            ariaLabel="oval-loading"
            wrapperClass={styles.spinner}
            secondaryColor='rgb(255, 166, 77)'
          />
        </div>
        }
    </div>
  
  );
}

export default AddButton;

