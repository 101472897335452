import styles from '../css/editcircle.module.css';

function EditCircle(props) {

  const types = ["chest", "back", "shoulder", "arm", "leg"];

  return (
    <div className={styles.circle}>
      <div className={styles.title}>
        Add exercise
      </div>
      <div className={styles.fields}>
        <div className={styles.field}>
          <select value={props.newitem.part} className={styles.select} onChange={(e) => props.setNewItem({...props.newitem, part: e.target.value})}>
            <option value="" hidden>Part</option>
            {types.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>  
        </div>
        <div className={styles.field}>
          <input 
            type='text'
            placeholder='Exercise Name'
            value={props.newitem.name}
            onChange={(e) => {
              props.setNewItem({...props.newitem, name: e.target.value})
            }}
            className={styles.input}
          />
        </div>
      </div>
      <button className={styles.button} onClick={props.addNewItem}>
        Add
      </button>
    </div>
  );
}
  
export default EditCircle;
  