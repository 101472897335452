

function MemoEdit() {

  return (
    <div style={{paddingTop: "8vh", paddingBottom: "8vh"}}>
      edit
    </div>
  );
}
  
export default MemoEdit;