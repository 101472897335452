import Contents from "./elements/Contents";
import Form from "./elements/Form";
import Logout from "./elements/Logout";
function Main() {

  return (
    <>
      <Contents />
      <Form />
      <Logout />
    </>
  );
}
    
export default Main;
    