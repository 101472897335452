import { Link, useLocation } from 'react-router-dom';
import styles from './css/footer.module.css';

function Footer() {
  const location = useLocation();

  return (
    <div className={styles.footer}>
      {location.pathname === "/apps/budget/planner" ? (
        <div className={styles.button_selected}>
          <i className="bi bi-piggy-bank"></i>
        </div>
      ): (
        <Link to="./planner" className={styles.button}>
          <i className="bi bi-piggy-bank"></i>
        </Link>
      )}
      {location.pathname === "/apps/budget/editor" ? (
        <div className={styles.button_selected}>
          <i className="bi bi-pencil-square"></i>
        </div>
      ): (
        <Link to="./editor" className={styles.button}>
          <i className="bi bi-pencil-square"></i>
        </Link>
      )}
      {location.pathname === "/apps/budget/payment" ? (
        <div className={styles.button_selected}>
          <i className="bi bi-cart-check"></i>
        </div>
      ): (
        <Link to="./payment" className={styles.button}>
          <i className="bi bi-cart-check"></i>
        </Link>
      )}
    </div>
  );
}
  
export default Footer;
  