import { Link, useLocation } from 'react-router-dom';
import styles from '../css/footer.module.css';

function Footer() {
  const location = useLocation();

  return (
    <div className={styles.footer}>
      {location.pathname === "/apps/workout/record" ? (
        <div className={styles.button_selected}>
          <i className="bi bi-journal-check"></i>
        </div>
      ): (
        <Link to="./record" className={styles.button}>
          <i className="bi bi-journal-check"></i>
        </Link>
      )}
      {location.pathname === "/apps/workout/edit" ? (
        <div className={styles.button_selected}>
          <i className="bi bi-pencil-square"></i>
        </div>
      ): (
        <Link to="./edit" className={styles.button}>
          <i className="bi bi-pencil-square"></i>
        </Link>
      )}
    </div>
  );
}
  
export default Footer;
  