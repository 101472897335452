import styles from '../css/editbox.module.css';

function EditBox(props) {

  function deleteItem (inputId) {
    const deletedItems = props.items.filter(item => item.id !== inputId);
    props.setItems(deletedItems);
  };

  return (
    <div className={styles.box}>
      <div className={styles.title}>
        {props.title}
      </div>
      <div className={styles.items}>
        {props.items.map(item => (
          <div className={styles.item} key={item.id}>
            <button className={styles.button} onClick={() => deleteItem(item.id)}>
              delete
            </button>
            <input
              type="text"
              placeholder='name'
              value={item.name}
              className={styles.name}
              onChange={(e) => {
                props.setItems(props.items.map(element => 
                  item.id === element.id ? {...element, name: e.target.value} : element
                ));
              }}
            />
            <input
              type="number"
              min={0}
              placeholder='weight'
              value={item.weight}
              className={styles.weight}
              onChange={(e) => {
                props.setItems(props.items.map(element => 
                  item.id === element.id ? {...element, weight: e.target.value} : element
                ));
              }}
            />
            <input
              type="number"
              min={0}
              placeholder='times'
              value={item.times}
              className={styles.times}
              onChange={(e) => {
                props.setItems(props.items.map(element => 
                  item.id === element.id ? {...element, times: e.target.value} : element
                ));
              }}
            />
          </div> 
        ))}
      </div>
    </div>
  );
}
  
export default EditBox;
  