import { useOutletContext } from 'react-router-dom';
import styles from '../css/editor.module.css';
import EditorBox from './EditorBox';
import SaveButton from './SaveButton';
import EditorPayment from './EditorPayment';

function Editor() {

  const {
    bankitem,
    setBankItem,
    incomeitem,
    setIncomeItem,
    payitem,
    setPayItem,
    names,
    useditem,
    setUsedItem,
    filteredMonth,
    setFilteredMonth,
    isLoading,
    setIsLoading
  } = useOutletContext();

  return (
    <div className={styles.editor}>
      <EditorBox items={bankitem} setItems={setBankItem} title={names[0]}/>
      <EditorBox items={incomeitem} setItems={setIncomeItem} title={names[1]}/>
      <EditorBox items={payitem} setItems={setPayItem} title={names[2]}/>
      <EditorPayment useditem={useditem} setUsedItem={setUsedItem} filteredMonth={filteredMonth} setFilteredMonth={setFilteredMonth}/>
      <SaveButton bankitem={bankitem} incomeitem={incomeitem} payitem={payitem} useditem={useditem} isLoading={isLoading} setIsLoading={setIsLoading}/>
    </div>
  );
}
  
export default Editor;
  