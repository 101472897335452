import styles from '../css/paymentcircle.module.css';

function PaymentCircle(props) {

  return (
    <div className={styles.circle}>
      <div className={styles.title}>
        Used Money
      </div>
      <div className={styles.fields}>
        <div className={styles.field}>
            <input 
              type='number'
							min='0'
							placeholder='cost'
							value={props.newitem.amount}
							onChange={(e) => {
								props.setNewItem({...props.newitem, amount: e.target.value})
							}}
							className={styles.input}
						/>
        </div>
        <div className={styles.field}>
            <input
							type='text'
							placeholder='detail'
							value={props.newitem.placeholder}
							onChange={(e) => {
								props.setNewItem({...props.newitem, placeholder: e.target.value})
							}}
							className={styles.input}
						/>
        </div>
      </div>
    </div>
  );
}
  
export default PaymentCircle;