import styles from '../css/planner.module.css';

function PlannerBox(props) {

  return (
      <div className={styles.box}>
        <div className={styles.title}>
          {props.title}
        </div>
        <div className={styles.items}>
          {props.items.map((bank) => (
            <div className={styles.item} key={bank.id}>
              <label htmlFor={bank.id} className={styles.label}>
                {bank.placeholder}
              </label>
              <input
                type='number'
                min="0"
                id={bank.id}
                placeholder={bank.placeholder}
                value={bank.amount}
                className={styles.input}
                onChange={(e) => {
                  props.setItems(props.items.map(item => 
                    item.id === bank.id ? {...item, amount: e.target.value} : item
                  ));
                }}
              />
            </div>
          ))}
        </div>
      </div>

  );
}
  
export default PlannerBox;
  