import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import { useEffect, useState } from 'react';

function Budget() {

  //arrays for budget
  
  let idNumber = 0;
  
  const [ bankitem, setBankItem ] = useState([{id: "1", placeholder: "bank1", amount: "0"}]);
  const [ incomeitem, setIncomeItem ] = useState([{id: "2", placeholder: "5日", amount: "0"}]);
  const [ payitem, setPayItem ] = useState([{id: "3", placeholder: "food", amount: "0"}]);

  const names = [
    "Saving",
    "Income",
    "Payment"
  ];

  //arrays for payment
  const [ newitem, setNewItem ] = useState({id: idNumber++, placeholder: "", amount: "", date: ""});
  const [ useditem, setUsedItem ] = useState([]);
  const today = new Date();
  const [ filteredMonth, setFilteredMonth ] = useState(today.getMonth() +1);

  //for loading
  const [ isLoading, setIsLoading ] = useState(false);
  
  //props
  const contexts = {
    bankitem,
    setBankItem,
    incomeitem,
    setIncomeItem,
    payitem,
    setPayItem,
    names,
    useditem,
    setUsedItem,
    newitem,
    setNewItem,
    today,
    filteredMonth,
    setFilteredMonth,
    isLoading,
    setIsLoading
  };


  //DBからデータを取得
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_BUDGET_GET_I);
        const itemDatas = await response.json();
        if (Object.keys(itemDatas).length > 0) {
          if(process.env.REACT_APP_ENV === 'php') {
            for (let key in itemDatas) {
              if (key === "userId") {
              }
              else {
                itemDatas[key] = JSON.parse(itemDatas[key]);
              }
            }
          }
          setBankItem(itemDatas.bankitem);
          setIncomeItem(itemDatas.incomeitem);
          setPayItem(itemDatas.payitem);
          setUsedItem(itemDatas.useditem);
        }
      } catch (error) {
        alert('network error');
      }
    })();
  }, []);

  return (
    < >
      <Header />
      <Outlet context={contexts}/>
      <Footer />
    </>
  );
}
  
export default Budget;
  