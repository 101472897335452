import { Link } from 'react-router-dom';
import styles from './css/contents.module.css';

function Contents() {

  const items1 = [
    {image: "food", path: "food/results"},
    {image: "workout", path: "workout/record"},
    {image: "budget", path: "budget/planner"},
  ]; 
  const items2 = [
    {image: "memo", path: "memo/record"},
    {image: "ec", path: "making"},
    {image: "music", path: "making"},
  ] 

  return (
    <div className={styles.contents} id="appsSection">
      <div className={styles.title}>
        Support Apps
      </div>
      <div className={styles.items}>
        {items1.map(item => (
          <div className={styles.item} key={item.image}>
            <Link to={`/apps/${item.path}`}>
              <img src={`./images/apps/${item.image}.jpg`} className={styles.image} alt="item pic" />   
            </Link>
          </div>
        ))}
      </div>
      <div className={styles.items}>
        {items2.map(item => (
          <div className={styles.item} key={item.image}>
            <Link to={`/apps/${item.path}`}>
              <img src={`./images/apps/${item.image}.jpg`} className={styles.image} alt="item pic" />   
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
      
export default Contents;
      