import { Oval } from 'react-loader-spinner';
import styles from '../css/button.module.css';

function Button({ handleSave, isLoading }) {

  return (
    <div className={styles.buttonbox}>
      <button className={styles.button} onClick={handleSave}>
        Save
      </button>
      {isLoading && 
        <div className={styles.spinnerbox}>
          <Oval
            visible={true}
            width="2rem"
            height="2rem"
            color="rgb(255, 166, 77)"
            ariaLabel="oval-loading"
            wrapperClass={styles.spinner}
            secondaryColor='rgb(255, 166, 77)'
          />
        </div>
        }
    </div>
  );
}
  
export default Button;
  