import { Outlet } from "react-router-dom";
import Header from "./elements/Header";
import Footer from "./elements/Footer";
import { useEffect, useState } from "react";
import { GetDB } from "./elements/GetDB";

function Food() {

  const [ carbItems, setCarbItems ] = useState([{id: "1", part: "Carb", foodname: "Name", cost: "", calory: "", times: ""}]); 
  const [ proteinItems, setProteinItems ] = useState([{id: "2", part: "Protein", foodname: "Name", cost: "", calory: "", times: ""}]); 
  const [ fatItems, setFatItems ] = useState([{id: "3", part: "Fat", foodname: "Name", cost: "", calory: "", times: ""}]); 
  const [ otherItems, setOtherItems ] = useState([{id: "4", part: "Others", foodname: "Name", cost: "", calory: "", times: ""}]); 
  const [ newitem, setNewItem ] = useState({id: "", part: "", foodname: "", cost: "", calory: "", times: "" });
  const [ isLoading, setIsLoading ] = useState(false);


  useEffect(() => {
    GetDB(process.env.REACT_APP_API_FOOD_GET)
      .then(data => {
        if(data) {
          if(process.env.REACT_APP_ENV === 'php') {
            for (let key in data) {
              if (key === "userId") {
              }
              else {
                data[key] = JSON.parse(data[key]);
              }
            }
          }
          setCarbItems(data.carbItems);
          setProteinItems(data.proteinItems);
          setFatItems(data.fatItems);
          setOtherItems(data.otherItems);
        }
      })
  }, []);



  const contexts = {
    carbItems,
    setCarbItems,
    proteinItems,
    setProteinItems,
    fatItems,
    setFatItems,
    otherItems,
    setOtherItems,
    newitem,
    setNewItem,
    isLoading,
    setIsLoading
  };


  return (
    < >
      <Header />
      <Outlet context={contexts} />
      <Footer />
    </>
  );
}
  
export default Food;