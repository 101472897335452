import { Link } from 'react-router-dom';
import styles from '../css/header.module.css';

function Header() {

  return (
    <div className={styles.header}>
        <div className={styles.title}>
          Contents Idea
        </div>
        <Link to="/" className={styles.button}>
          <i className="bi bi-house"></i>
        </Link>
    </div>
  );
}
  
export default Header;
  