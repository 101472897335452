import { Link, useLocation } from 'react-router-dom';
import styles from '../css/footer.module.css';

function Footer() {
  const location = useLocation();

  return (
    <div className={styles.footer}>
      {location.pathname === "/apps/food/results" ? (
        <div className={styles.button_selected}>
          <i className="bi bi-coin"></i>
        </div>
      ): (
        <Link to="./results" className={styles.button}>
          <i className="bi bi-coin"></i>
        </Link>
      )}
      {location.pathname === "/apps/food/edit" ? (
        <div className={styles.button_selected}>
          <i className="bi bi-pencil-square"></i>
        </div>
      ): (
        <Link to="./edit" className={styles.button}>
          <i className="bi bi-pencil-square"></i>
        </Link>
      )}
    </div>
  );
}
  
export default Footer;
  