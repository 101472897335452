import { useOutletContext } from 'react-router-dom';
import styles from '../css/payment.module.css';
import PaymentCircle from './PaymentCircle';
import AddButton from './AddButton';
import PaymentBox from './PaymentBox';

function Payment() {

  
  //props
  const {
    useditem,
    setUsedItem,
    newitem,
    setNewItem,
    today,
    filteredMonth,
    setFilteredMonth,
    isLoading,
    setIsLoading
  } = useOutletContext();

  return (
    <div className={styles.payment}>
        <PaymentCircle useditem={useditem} setUsedItem={setUsedItem} newitem={newitem} setNewItem={setNewItem}/>
        <AddButton useditem={useditem} setUsedItem={setUsedItem} newitem={newitem} setNewItem={setNewItem} today={today} isLoading={isLoading} setIsLoading={setIsLoading}/>
        <PaymentBox useditem={useditem} filteredMonth={filteredMonth} setFilteredMonth={setFilteredMonth}/>
    </div>
  );
}
  
export default Payment;
  