import { Link } from "react-router-dom";

function Making() {

  return (
    <div style={{display: "flex", alignItems: "center", flexDirection: "column", paddingTop: "2rem"}}>
      <h1>作成中です、完成をお待ちください</h1>
      <div><Link to="/">トップページへ</Link></div>
    </div>
    
  );
}
  
export default Making;
  