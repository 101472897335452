import { useState } from 'react';
import { Oval } from 'react-loader-spinner';
import styles from './css/form.module.css';

function Form() {
  const appEnv = process.env.REACT_APP_ENV;

  const formlist = [
    {placeholder: "（必須）お名前", classname: "yourname"},
    {placeholder: "（必須）メールアドレス", classname: "mail"},
    {placeholder: "（任意）電話番号", classname: "phone"},
    {placeholder: "（必須) アプリに関する問い合わせ・改善点", classname: "comment"}
  ];
  const [formInputs, setFormInputs] = useState({
    yourname: "",
    mail: "",
    phone: "",
    comment: ""
  }); 

  const [Loading, setLoading] = useState(false);

  //function
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await fetch(process.env.REACT_APP_API_MAIL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formInputs)
    });
    if(appEnv === 'node') {
      const result = await response.text();
      if(result === 'success') {
        alert('Succeed');
      }
      else {
        alert('Error. Try again.');
      }
    }
    else if (appEnv === 'php'){
      const result = await response.json();
      if(result.status === 'success') {
        alert('Succeed');
      }
      else {
        alert('Error. Try again.');
      }
    }
    else {
      alert('Network error.')
    }

    setFormInputs({
      yourname: "",
      mail: "",
      phone: "",
      comment: ""
    });
    setLoading(false);
  };


  return (
    <form className={styles.form} id="formSection" onSubmit={handleSubmit}>
      <div className={styles.title}>
        Contact
      </div>
      <div className={styles.inputs}>
      {formlist.map((item, index) => {
        if (item.classname === "comment") {
          return (
            <textarea
              key={index}
              value={formInputs[item.classname]}
              placeholder={item.placeholder}
              onChange={(e) => setFormInputs({...formInputs, [item.classname]: e.target.value})}
              className={styles[item.classname]}
              required
              maxLength={500}
            />
          );
        } else if (item.classname === "phone") {
          return (
            <input
              key={index}
              type='tel'
              value={formInputs[item.classname]}
              placeholder={item.placeholder}
              onChange={(e) => setFormInputs({...formInputs, [item.classname]: e.target.value})}
              className={styles[item.classname]}
              maxLength={20}
            />
          );
        } else if (item.classname === "mail") {
          return (
            <input
              key={index}
              type='email'
              value={formInputs[item.classname]}
              placeholder={item.placeholder}
              onChange={(e) => setFormInputs({...formInputs, [item.classname]: e.target.value})}
              className={styles[item.classname]}
              required
              maxLength={50}
            />
          );
        } else {
          return (
            <input
              key={index}
              type='text'
              value={formInputs[item.classname]}
              placeholder={item.placeholder}
              onChange={(e) => setFormInputs({...formInputs, [item.classname]: e.target.value})}
              className={styles[item.classname]}
              required
              maxLength={20}
            />
          );
        }
      })}
      </div>
      <div className={styles.buttonload}>
        <button type="submit" className={styles.button}>
          送信
        </button>
        {Loading && 
          <div className={styles.spinnerbox}>
            <Oval
              visible={true}
              width="2rem"
              height="2rem"
              color="rgb(255, 166, 77)"
              ariaLabel="oval-loading"
              wrapperClass={styles.spinner}
              secondaryColor='rgb(255, 166, 77)'
            />
          </div>
        }
      </div>
    </form>
  );
}
      
export default Form;
      




