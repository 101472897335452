import { useOutletContext } from "react-router-dom";
import EditBox from "./elements/EditBox";
import EditCircle from "./elements/EditCircle";
import Button from "./elements/Button";
import { UpdateDB } from "../workout/elements/UpdateDB";


function Edit() {

  const {
    chestItems,
    backItems,
    shoulderItems,
    armItems,
    legItems,
    setChestItems,
    setBackItems,
    setShoulderItems,
    setArmItems,
    setLegItems,
    newitem,
    setNewItem,
    isLoading,
    setIsLoading
  } = useOutletContext();


  
  //add newitem
  const updateItems = {
    chest: chestItems,
    back: backItems,
    shoulder: shoulderItems,
    arm: armItems,
    leg: legItems
  };
  const updateFunctions = {
    chest: setChestItems,
    back: setBackItems,
    shoulder: setShoulderItems,
    arm: setArmItems,
    leg: setLegItems
  };
  function addNewItem () {
    if(newitem.part && newitem.name) {
      updateFunctions[newitem.part]([...updateItems[newitem.part], {...newitem, id: Math.random().toString(36).substring(7)}]);
      alert("New exercise added!");
      setNewItem({id: "", part: "", name: "", weight: "", times: ""});
    }
    else {
      alert("choose type & write name");
      setNewItem({...newitem, name: "", part: ""});
    }
  };

  //save
  const saveData = {
    chestItems: chestItems,
    backItems: backItems,
    shoulderItems: shoulderItems,
    armItems: armItems,
    legItems: legItems
  };
  const handleSave = async () => {
    setIsLoading(true);
    await UpdateDB(saveData, process.env.REACT_APP_API_WORKOUT_SAVE);
    setIsLoading(false);
  };

  return (
    <div style={{paddingTop: "8vh", paddingBottom: "8vh", backgroundColor: "rgb(110, 110, 110)"}}>
      <Button handleSave={handleSave} isLoading={isLoading}/>
      <EditCircle newitem={newitem} setNewItem={setNewItem} addNewItem={addNewItem}/>
      <EditBox items={chestItems} setItems={setChestItems} title={"Chest"} />
      <EditBox items={backItems} setItems={setBackItems} title={"Back"} />
      <EditBox items={shoulderItems} setItems={setShoulderItems} title={"Shoulder"} />
      <EditBox items={armItems} setItems={setArmItems} title={"Arm"} />
      <EditBox items={legItems} setItems={setLegItems} title={"Leg"} />
    </div>
  );
}
  
export default Edit;
  