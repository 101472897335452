import { useOutletContext } from "react-router-dom";
import RecordBox from "./elements/RecordBox";

function MemoRecord() {

  const  {
    instaItems,
    podItems,
    xItems,
    appItems,
    impItems
  } = useOutletContext();

  return (
    <div style={{paddingTop: "8vh", paddingBottom: "8vh"}}>
      <RecordBox title={"Instagram"} items={instaItems} />
      <RecordBox title={"Podcast"} items={podItems} />
      <RecordBox title={"X"} items={xItems} />
      <RecordBox title={"App"} items={appItems} />
      <RecordBox title={"Improvement"} items={impItems} />
    </div>
  );
}
  
export default MemoRecord;