import { Oval } from 'react-loader-spinner';
import { UpdateDB } from '../UpdateDB';
import styles from '../css/savebutton.module.css';

function SaveButton(props) {
  const saveData = {
    bankitem: props.bankitem,
    incomeitem: props.incomeitem,
    payitem: props.payitem,
    useditem: props.useditem
  };

  const handleSave = async () => {
    props.setIsLoading(true);
    await UpdateDB(saveData, process.env.REACT_APP_API_BUDGET_SAVE_A);
    props.setIsLoading(false);
  };


  return (
    <div className={styles.savebutton}>
      <button className={styles.button} onClick={handleSave}>
        Save
      </button>
      {props.isLoading && 
        <div className={styles.spinnerbox}>
          <Oval
            visible={true}
            width="2rem"
            height="2rem"
            color="rgb(255, 166, 77)"
            ariaLabel="oval-loading"
            wrapperClass={styles.spinner}
            secondaryColor='rgb(255, 166, 77)'
          />
        </div>
        }
    </div>
  );
}
  
export default SaveButton;
  





