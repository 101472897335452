import styles from '../css/circle.module.css';

function PlannerCircle(props) {

  //caluculation
  const sumSaving = props.bankitem.reduce((total, item) => total + Number(item.amount), 0);
  const sumPayment = props.payitem.reduce((total, item) => total + Number(item.amount), 0);
 
  let freeMoney = sumSaving - sumPayment;

  //paymentを引く
  const sumUsed = props.useditem.reduce((total, item) => item.date.month === props.today.getMonth()+1 ?  total+Number(item.amount) : total, 0);
  freeMoney = freeMoney - sumUsed;

  const results = props.incomeitem.map(income => (
      freeMoney = freeMoney+Number(income.amount)
  ));
  

  return (
    <div className={styles.circle}>
      <div className={styles.title}>
        Free budget
      </div>
      <div className={styles.items}>
        {props.incomeitem.map((income, index) => (
          <div className={styles.item} key={income.id}>
            {income.placeholder}まで {results[index]}円
          </div>
        ))}
      </div>
    </div>
  );
}
  
export default PlannerCircle;