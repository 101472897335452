export async function UpdateDB (saveData, fetchPath) {
  try {
    const response = await fetch(fetchPath, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(saveData)
    });
    if(response.ok) {
      alert("Your data saved successfully");
    }
    else {
      alert("Error. Try one more time.");
    }
  } catch (error) {
    alert("Network error.");
  }
}