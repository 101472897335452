import styles from './footer.module.css';

function Footer() {

  return (
    <div className={styles.footer}>
      <div className={styles.sns}>
        <div className={styles.icon}>
          <a className={styles.link} target="_blank" rel="noopener noreferrer">
            <i className="bi bi-instagram"></i>
          </a>
        </div>
      </div>
      <div className={styles.copyright}>
        &copy; muscle All rights reserved
      </div>
    </div>
  );
}
    
export default Footer;
    