import { Outlet } from "react-router-dom";
import Header from "./elements/Header";
import Footer from "./elements/Footer";
import { useEffect, useState } from "react";
import { GetDB } from "./elements/GetDB";

function Workout() {

  const [ chestItems, setChestItems ] = useState([{id: "1", part: "chest", name: "Name", weight: "Weight", times: "Times"}]);
  const [ backItems, setBackItems ] = useState([{id: "2", part: "back", name: "Name", weight: "Weight", times: "Times"}]);
  const [ shoulderItems, setShoulderItems ] = useState([{id: "3", part: "shoulder", name: "Name", weight: "Weight", times: "Times"}]);
  const [ armItems, setArmItems ] = useState([{id: "4", part: "arm", name: "Name", weight: "Weight", times: "Times"}]);
  const [ legItems, setLegItems ] = useState([{id: "5", part: "leg", name: "Name", weight: "Weight", times: "Times"}]);
  const [ newitem, setNewItem ] = useState({id: "", part: "", name: "", weight: "", times: ""});
  const [ isLoading, setIsLoading ] = useState(false); 

  useEffect(() => {
    GetDB(process.env.REACT_APP_API_WORKOUT_GET)
      .then(data => {
        if(data) {
          if(process.env.REACT_APP_ENV === 'php') {
            for (let key in data) {
              if (key === "userId") {
              }
              else {
                data[key] = JSON.parse(data[key]);
              }
            }
          }
          setChestItems(data.chestItems);
          setBackItems(data.backItems);
          setShoulderItems(data.shoulderItems);
          setArmItems(data.armItems);
          setLegItems(data.legItems);
        }
      })
  }, []);


  const contexts = {
    chestItems,
    setChestItems,
    backItems,
    setBackItems,
    shoulderItems,
    setShoulderItems,
    armItems,
    setArmItems,
    legItems,
    setLegItems,
    newitem,
    setNewItem,
    isLoading,
    setIsLoading
  };

  return (
    < >
      <Header />
      <Outlet context={contexts} />
      <Footer />
    </>
  );
}
  
export default Workout;
  