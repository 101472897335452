import { useOutletContext } from "react-router-dom";
import ResultCircle from "./elements/ResultCircle";

function FoodResults() {

  const {
    carbItems,
    proteinItems,
    fatItems,
    otherItems,
  } = useOutletContext();

  //calculation
  const today = new Date();
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const days = endOfMonth.getDate() - today.getDate();
  
  const ItemsCost = (items) => {
    let total = 0;
    items.forEach(item => {
      if(item.cost && item.times) {
        total += item.cost * item.times;
      }
    });
    return total;
  };
  const itemsArray = [ carbItems, proteinItems, fatItems, otherItems ];
  const TotalCost = itemsArray.reduce((total, items) => total + ItemsCost(items), 0);

  const resultCost = TotalCost * days + "円";
  

  const ItemsCalory = (items) => {
    let total = 0;
    items.forEach(item => {
      if(item.calory && item.times) {
        total += item.calory * item.times;
      }
    });
    return total;
  };
  
  const TotalCarory = itemsArray.reduce((total, items) => total + ItemsCalory(items), 0);
  
  const resultCalory = TotalCarory + "kcal";

  return (
    <div style={{paddingTop: "8vh", paddingBottom: "8vh"}}>
      <ResultCircle title={"Monthly cost"} result={resultCost} />
      <ResultCircle title={"Calory per day"} result={resultCalory} />
    </div>
  );
}
  
export default FoodResults;