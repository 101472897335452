export async function GetDB (fetchPath) {
  try {
    const response = await fetch(fetchPath);
    const itemDatas = await response.json();
    if (Object.keys(itemDatas).length > 0) {
      return itemDatas;
    }
    else {
      alert('No Data from Network.')
    }
  } catch (error) {
    alert("Network error.");
  }
}