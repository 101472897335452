import { useOutletContext } from "react-router-dom";
import EditBox from "./elements/EditBox";
import EditCircle from "./elements/EditCircle";
import { UpdateDB } from "./elements/UpdateDB";
import Button from "./elements/Button";


function FoodEdit() {

  const {
    carbItems,
    setCarbItems,
    proteinItems,
    setProteinItems,
    fatItems,
    setFatItems,
    otherItems,
    setOtherItems,
    newitem, 
    setNewItem,
    isLoading,
    setIsLoading
  } = useOutletContext();

  const updateFunctions = {
    Carb: setCarbItems,
    Protein: setProteinItems,
    Fat: setFatItems,
    Others: setOtherItems
  };
  const updateItems = {
    Carb: carbItems,
    Protein: proteinItems,
    Fat: fatItems,
    Others: otherItems
  };

  function addNewItem () {
    if(newitem.part && newitem.foodname) {
      updateFunctions[newitem.part]([...updateItems[newitem.part], {...newitem, id: Math.random().toString(36).substring(7)}]);
      alert("New exercise added!");
      setNewItem({id: "", part: "", foodname: "", cost: "", calory: "", times: "" });
    }
    else {
      alert("choose type & write name");
      setNewItem({...newitem, foodname: "", part: ""});
    }
  };


  const saveData = {
    carbItems: carbItems,
    proteinItems: proteinItems,
    fatItems: fatItems,
    otherItems: otherItems
  };
  const handleSave = async () => {
    setIsLoading(true);
    await UpdateDB(saveData, process.env.REACT_APP_API_FOOD_SAVE);
    setIsLoading(false);
  };



  return (
    <div style={{paddingTop: "8vh", paddingBottom: "8vh"}}>
      <Button handleSave={handleSave} isLoading={isLoading} />
      <EditCircle newitem={newitem} setNewItem={setNewItem} addNewItem={addNewItem}/>
      <EditBox items={carbItems} setItems={setCarbItems} title={"Carb"} />
      <EditBox items={proteinItems} setItems={setProteinItems} title={"Protein"} />
      <EditBox items={fatItems} setItems={setFatItems} title={"Fat"} />
      <EditBox items={otherItems} setItems={setOtherItems} title={"Others"} />
    </div>
  );
}
  
export default FoodEdit;